import React from 'react'
import { Container } from 'react-bootstrap'
import { tableData } from '../const/tableData'

const PriceTable = () => {

    const cross = "/cross.svg"

    const style = {
        width: "40px",
        height: "40px"
    }

    const crossStyle = {
        width: "25px",
        height: "40px",
        marginLeft: "7px"
    }




    return (
        <>
            <div className="faq" id="products" >
                <h3> Compare full set of Feature </h3>
                <div className="content">
                    <h4 className='price-tag' >
                        Flexible, transparent pricing that scales with your needs.
                    </h4>
                </div>
                <Container>
                    <div className='table-ctn' >
                        <table border="1">
                            <thead>
                                <tr>
                                    <th className='th-styled' >Feature</th>
                                    <th className='th-styled'>BASIC</th>
                                    <th className='th-styled'>STANDARD</th>
                                    <th className='th-styled'>PREMIUM</th>
                                </tr>
                            </thead>

                            <tbody>
                                {tableData.map((rowData, index) => (
                                    <tr key={index}>
                                        <td>{rowData.name}</td>
                                        <td><img style={rowData?.basic === cross ? crossStyle : style} src={rowData.basic} alt="Basic" /></td>
                                        <td><img style={rowData?.standard === cross ? crossStyle : style} src={rowData.standard} alt="Standard" /></td>
                                        <td><img style={rowData?.premium === cross ? crossStyle : style} src={rowData.premium} alt="Dispatch System" /></td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>

                    </div>
                </Container>
            </div>
        </>
    )
}

export default PriceTable