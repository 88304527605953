import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsOfService from "./pages/termsOfService";
import CookiesPolicy from "./pages/cookiesPolicy";

const App = () => {

  return (
    <>
      <Routes>
        <Route
          exact
          path="/terms-of-service"
          element={
            <TermsOfService
              metaTitle="Agreement for Using A To Z Dispatch | Terms and Conditions"
              metaContent="Before using A To Z Dispatch, please read and understand our terms and conditions. Our agreement outlines the rules and regulations for using our platform and creating your website."
            />
          }
        />

        <Route
          exact
          path="/privacy-policy"
          element={
            <PrivacyPolicy
              metaTitle="How We Protect Your Privacy on A To Z Dispatch"
              metaContent="At A To Z Dispatch, we are committed to protecting your privacy. Our privacy policy outlines how we collect, use, and protect your personal information when you use our platform to create your website."
            />
          }
        />

        <Route
          exact
          path="/cookies-policy"
          element={
            <CookiesPolicy
              metaTitle="Understanding Cookies on A To Z Dispatch "
              metaContent="Our website builder uses cookies to enhance your browsing experience. Learn more about our cookies policy and how we use cookies to improve our website functionality."
            />
          }
        />

        <Route
          exact
          path="/"
          element={
            <Index
              metaTitle="White Label Dispatch - #1 Taxi Dispatch System For Startups | A To Z Dispatch"
              metaContent="Power packed with new features. Book free demo now. Manage your taxi business operations with most reliable taxi dispatch software"
            />
          }
        />
      </Routes>
    </>
  );
};
export default App;
