export const tableData = [
  {
    name: "Unlimited Passengers",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Unlimited Drivers",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Unlimited Vehicles",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Unlimited Updates",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Booking Management",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Dispatch System",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "CMS",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Reporting & Analytics",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Passenger App (PWA) with Hosting & Tech Support",
    basic: "/cross.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "SMS/email notifications",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Flight Tracking",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Job Text/Email Reminder (Passenger/Driver)",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Driver Tracking",
    basic: "/cross.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Fleet Manager",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Driver Manager",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Driver Scheduling",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Rate Manager",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Location Manager",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Additional Rate Manager",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Card/PayPal Payment",
    basic: "/tick-green.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Mobile-Responsive Website with Hosting & Tech Support",
    basic: "/tick-green.svg",
    standard: "/cross.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Passenger Booking App (PWA)",
    basic: "/cross.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
  {
    name: "Driver App (Native Atoz Dispatch App for Android and iOS)",
    basic: "/cross.svg",
    standard: "/tick-green.svg",
    premium: "/tick-green.svg",
  },
];
