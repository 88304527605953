import { Container, Row, Col } from "react-bootstrap";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  useEffect(() => {
    // Script for sd variant
    const scriptSD = document.createElement("script");
    scriptSD.async = true;
    scriptSD.src = "https://b.sf-syn.com/badge_js?sf_id=3707150&variant_id=sd";
    document
      .getElementsByTagName("script")[0]
      .parentNode.insertBefore(
        scriptSD,
        document.getElementsByTagName("script")[0]
      );

    // Script for sf variant
    const scriptSF = document.createElement("script");
    scriptSF.async = true;
    scriptSF.src = "https://b.sf-syn.com/badge_js?sf_id=3707150&variant_id=sf";
    document
      .getElementsByTagName("script")[0]
      .parentNode.insertBefore(
        scriptSF,
        document.getElementsByTagName("script")[0]
      );

    // Cleanup if needed
    return () => {
      document
        .getElementsByTagName("script")[0]
        .parentNode.removeChild(scriptSD);
      document
        .getElementsByTagName("script")[0]
        .parentNode.removeChild(scriptSF);
    };
  }, []);

  return (
    <footer>
      <Container fluid>
        <Row>
          <Col md={4}>
            <div className="left-part">
              <p> Copyright@{getCurrentYear()}. All Rights Reserved </p>
            </div>
          </Col>
          <Col md={3}>
            <div className="middle-part">
              <Link to="/terms-of-service">Terms of Service</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/cookies-policy">Cookie Policy</Link>
            </div>
          </Col>
          <Col md={5}>
            {/* <div className="right-part">
                    <ul>
                        <li> <Link to="https://www.facebook.com/taxiwebdesigncompany"> <img src={facebook} alt="facebook" /> </Link></li>
                        <li> <Link to="https://twitter.com/taxi_web"> <img src={twitter} alt="twitter" /></Link></li>
                        <li> <Link to="https://www.youtube.com/@taxiwebdesign"> <img src={youtube} alt="youtube" /></Link></li>
                        <li> <Link to="https://www.instagram.com/taxiwebdesign/"> <img src={instagram} alt="instagram" /> </Link></li>
                        <li> <Link to="https://www.tiktok.com/@taxiwebdesign"> <img src={tiktok} alt="tiktok" /> </Link></li>
                        <li> <Link to="https://www.linkedin.com/company/taxiwebdesign/"> <img src={linkedin} alt="linkedin" /> </Link></li>                                
                    </ul>
                </div> */}
          </Col>
          <Col md={12}>
            <div className="d-flex justify-content-center">
              <div
                className="sf-root"
                data-id="3707150"
                data-badge="users-love-us-new-white"
                data-variant-id="sd"
                style={{
                  display: "block",
                  textAlign: "right",
                  marginTop: "30px",
                  width: "75px",
                }}
              >
                <a
                  href="https://slashdot.org/software/p/A-to-Z-Dispatch/"
                  target="_blank"
                >
                  A to Z Dispatch Reviews
                </a>
              </div>

              <div
                className="sf-root"
                data-id="3707150"
                data-badge="light-default"
                data-variant-id="sf"
                style={{
                  display: "block",
                  textAlign: "left",
                  marginTop: "30px",
                  width: "75px",
                }}
              >
                <a
                  href="https://sourceforge.net/software/product/A-to-Z-Dispatch/"
                  target="_blank"
                >
                  A to Z Dispatch Reviews
                </a>
              </div>
              <br />
              <br />
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
export default Footer;
