import React from 'react'
import { Container } from 'react-bootstrap'
import { IoLocationSharp } from "react-icons/io5";
import { BiDesktop } from "react-icons/bi";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
// import { LuAppWindow } from "react-icons/lu";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { MdOutlinePhonelink } from "react-icons/md";







const NewBannerCard = () => {




    const style = {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        justifyContent: 'space-between',
        // marginTop: '-120px', // You can include this if needed
        padding: '5px 0'
    };





    return (
        <>
            <Container>
                <div style={style} className='new-card-container'>
                    <div className='card' >
                        <div><IoLocationSharp className='react-icons' /></div>
                        <p>Dispatch System</p>
                    </div>
                    <div className='card' >
                        <div ><BiDesktop className='react-icons' /></div>
                        <p>Web Booker</p>
                    </div>
                    <div className='card' >
                        <div><MdOutlineScreenSearchDesktop className='react-icons' /></div>
                        <p>Web Booking Manager</p>
                    </div>
                    <div className='card' >
                        <div><IoPhonePortraitOutline className='react-icons' /></div>
                        <p>Branded Driver App </p>
                    </div>
                    <div className='card' >
                        <div><MdOutlinePhonelink className='react-icons' /></div>
                        <p>Branded Passenger App</p>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default NewBannerCard