import React, { useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import service1 from "../assets/image/service1.png";
import service2 from "../assets/image/service2.png";
import service3 from "../assets/image/service3.png";
import playIcon from "../assets/image/play-circle.svg";
import passengerAppVideo from "../assets/videos/ATOZ_DISPATCH_SYSTEM1.mp4";
import driverAppVideo from "../assets/videos/ATOZ_DRIVER.mp4";
import adminVideo from "../assets/videos/ATOZ_DISPATCH_SYSTEM2.mp4";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Services = () => {
  const [show, setShow] = useState(false);
  const [path, setPath] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showModal = (link) => {
    handleShow()
    setPath(link)
  }


  const servicesData = [
    {
      num: '1.',
      title: "Web Booker System",
      image: service1,
      description: "Your control center, allowing you to manage bookings, drivers, and passengers all in one place.Operator receive the booking, confirm the booking and assign the booking to driver.",
      link: adminVideo
    },
    {
      num: '2.',
      title: "Passenger App",
      image: service2,
      description: "Passenger can make booking from app, web or call operator to make a booking.",
      link: passengerAppVideo
    },
    {
      num: '3.',
      title: "Driver App",
      image: service3,
      description: "Empowering your drivers with real-time job notifications and seamless job management.Driver can accept or reject the job.",
      link: driverAppVideo
    },
  ];

  return (
    <>
      <div className="services">
        <Container>
          <h3>
            Our Dispatch System Includes
          </h3>
          {servicesData.length > 0 ? servicesData.map((item, id) => {
            return (
              <>
                <Row>
                  <Col md={6} className="service-info">

                    <span> {item.num} </span>
                    <h2> {item.title} </h2>
                    <p>{item.description}</p>
                    {item.link != "" ?
                      <button className="watch-video-btn" onClick={() => showModal(item.link)}>
                        <img src={playIcon} alt="play icon" />
                        <h5>Watch Video</h5>
                      </button>
                      : ""}
                  </Col>
                  <Col md={6}>
                    <img src={item.image} alt="image" />
                  </Col>
                </Row>
              </>
            );
          })
            : ""}
        </Container>
      </div>
      <Modal show={show} onHide={handleClose} className="full-modal">
        <Button variant="secondary" onClick={handleClose}>
          X
        </Button>
        <video width="100%" height="100%" controls="false" autoPlay="true">
          <source src={path} type="video/mp4" />
        </video>
      </Modal>
    </>
  );
};
export default Services;
