import React from 'react';
import { Collapse } from 'antd';

const style = {
    color: "#fff",
    textAlign: "left",
    fontWeight: 500,
    paddingLeft: "20px"
}

const headerStyle = {
    color: "#fff",
    textAlign: "left",
    fontWeight: 500,
    fontSize: "16px",
    color: "#ec6408"

}



const items = [
    {
        key: '1',
        label: <h2 style={headerStyle} >What are the features and what do i get for each packages</h2>,
        children: <p style={style} >Please refer to the pricing section on the home page of our website for detailed information about the features included in each package.</p>,
    },
    {
        key: '2',
        label: <h2 style={headerStyle} >Do I have to sign a long-term commitment?</h2>,
        children: <p style={style} >No, you are not locked into a long-term commitment. The service operates on a month-to-month basis, giving you flexibility.</p>,
    },
    {
        key: '3',
        label: <h2 style={headerStyle} >Can I change my plan later?</h2>,
        children: <p style={style} >Yes, you can scale your plan up or down according to the evolving needs of your business. Just let us know, and we'll adjust your plan accordingly.</p>,
    },
    {
        key: '4',
        label: <h2 style={headerStyle} >How does billing work?</h2>,
        children: <p style={style} >There is an upfront fee to license the software. Our system charges the card on file in arrears for the monthly dues, making the payment process convenient for you.</p>,
    },
    {
        key: '5',
        label: <h2 style={headerStyle} >What is your cancellation policy?</h2>,
        children: <p style={style} >You can cancel the service by sending an email to support@atozdispatch.com. Please note that a minimum of 30 days' notice is required for cancellation.</p>,
    },
    {
        key: '6',
        label: <h2 style={headerStyle} >How can I reactivate service?</h2>,
        children: <p style={style} >To reactivate the service, simply submit a request to support@atozdispatch.com. Please be aware that a monthly payment is required for the package pricing, and a reactivation fee may be assessed. Additionally, any accounts with an outstanding balance will need to be brought up to date before reactivation can occur</p>,
    },
    {
        key: '7',
        label: <h2 style={headerStyle} >Will the software and system be updated regularly?</h2>,
        children: <p style={style} >Yes the system and software will be updated in frequent period of time and it will available for you</p>,
    },
];
const CustomCollapse = () => {
    const onChange = (key) => {
        console.log(key);
    };
    return <Collapse items={items} destroyInactivePanel={false} ghost bordered={false} defaultActiveKey={['1']} onChange={onChange} />;
};
export default CustomCollapse;