import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/image/logo.svg";
import Modal from "react-bootstrap/Modal";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { CircularProgress } from "@material-ui/core";
import { postRequest } from "../Helpers/APIHelper";
import { IoMdPlay } from "react-icons/io";
import introVideo from "../assets/videos/ATOZ_DISPATCH_SYSTEM.mp4";



const Header = (props) => {

  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleCloseVideoModal = () => setShowVideoModal(false);
  const handleShowVideoModal = () => setShowVideoModal(true);

  const showModal = (link) => {
    handleShow()
  }


  const navigate = useNavigate()
  const [captchaValue, setCaptchaValue] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [message, setMessage] = useState(false);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const handleClose = () => {
    setShow(false);
    setFormData({
      name: "",
      email: "",
      phone: "",
    });
  };

  const handleShow = () => {
    setMessage(false);
    setShow(true);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmitDemo = (e) => {
    e.preventDefault();
    if (!captchaValue) {
      alert("Please verify that you are not a robot.");
      return;
    }
    setBtnDisable(true);
    postRequest("request-a-demo", formData)
      .then((response) => {
        setBtnDisable(false);
        if (response.status) {
          setMessage(true);
        } else {
          if (response?.errors ?? false) {
            alert(response?.message ?? "");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error ?? "-");
      });
  };
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
      </Helmet>
      <header>
        <Container fluid>
          <div className="navbar">
            <div className="right-part">
              <Link to="/">
                <img alt="stack overflow" src={logo}></img>
              </Link>
              <span>A2Z Dispatch</span>
            </div>
            <div className="left-part">
              {/* <Link to="https://accounts.urride.online/login/"   target="_blank" style={{border:'2px solid #54A937', marginRight:'10px', textDecoration:'none',  fontWeight:'600',  textAlign: 'center', padding: '5px', display: 'inline-block', color:'#212121', borderRadius: '30px',  width: '132px',height:' 40px'}}> Login </Link> */}
              <div style={{ display: "flex", justifyContent: "end" }} className="get-started">
                {/* <Link  style={{background:'#54A937', color:'#fff'}}>
                  <div className="drop-menu">
                  Quick Demo 
                  </div>
                  
                </Link> 
                <div className="dropdown">
                  <ul>
                  <li><a className="link" href="https://booking.atozdispatch.com/" target="_blank">Web Version</a></li>
                    <li><a className="link" href="https://play.google.com/store/apps/details?id=com.passenger.a2zdispatch" target="_blank">Android Passenger</a></li>
                    <li><a className="link"  href="https://play.google.com/store/apps/details?id=com.driver.a2zdispatch"  target="_blank">Android Driver</a></li>
                    <li><a className="link"  href="https://apps.apple.com/us/app/a2zdispatch/id6467181791"  target="_blank">iOS Passenger</a></li>
                    <li><a className="link"  href="https://apps.apple.com/us/app/a2zdispatch-driver/id6467396837" target="_blank">iOS Driver</a></li>
                  </ul>
                </div> */}


                <button onClick={handleShowVideoModal} className="header-outlined-btn">
                  <IoMdPlay size={20} />
                  Watch Demo
                </button>

                <Modal show={showVideoModal} onHide={handleCloseVideoModal} className="full-modal">
                  <Button variant="secondary" onClick={handleCloseVideoModal}>
                    X
                  </Button>
                  <video width="100%" height="100%" controls="false" autoPlay="true">
                    <source src={introVideo} type="video/mp4" />
                  </video>
                </Modal>

              </div>
              {/* &nbsp; &nbsp; */}
            </div>
          </div>
        </Container>
      </header>
      <div className="hide-in-large">
        <Link to={"https://buy.stripe.com/fZe8A61exdjhas014Q"} >
          <button className=" fixed-button header-fixed-btn ">
            Order Now
          </button>
        </Link>
      </div>

      <Modal show={show} className="centered-modal" onHide={handleClose}>
        <Modal.Header>
          <a href="#!" className="close-modal" onClick={handleClose}>
            Close
          </a>
          {message ? (
            <Modal.Title>Congratulation!</Modal.Title>
          ) : (
            <Modal.Title>Request a Demo</Modal.Title>
          )}
        </Modal.Header>
        {message ? (
          <div className="success-message">
            We have sent you all the demo credentials for Backend, Android and
            IOS passenger and driver app. Please kindly check your inbox or spam
          </div>
        ) : (
          <Form onSubmit={handleSubmitDemo}>
            <Modal.Body>
              <div className="single-banner-card">
                <Row>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Full Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="number"
                        placeholder="Contact Number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <ReCAPTCHA
                  sitekey="6Ldoys0iAAAAAKMK-xJT3IntXQFFcIgWotiVhaKG"
                  onChange={handleCaptchaChange}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button variant="danger" type="button" onClick={handleClose}>
              Close
            </Button> */}
              <Button variant="success" type="submit" disabled={btnDisable}>
                Submit {btnDisable && <CircularProgress size={20} />}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Modal>

      {/* <div className="hide-lg">
        <Link to="https://accounts.urride.online/register/"  target="_blank"> Order Now </Link>
      </div> */}
    </>
  );
};
export default Header;
